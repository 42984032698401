import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import axios from "axios";
import gpuHubTransactionApi from '@/api/gpuHub/gpuHubTransaction';
import { debounce } from 'vue-debounce';
import { transactionStatusText } from "@/constant/vmMachineStatusConst";
import moment from "moment";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBoxWithoutLoading';
import { colorGpuNodeColor } from "@/constant/vmMachineStatusConst";
import { mapState } from "vuex";
import { providerSelection } from "@/constant/config";
import { getlazyLoadInfo } from "@/scripts/_helpers/userHelper";
import mappingTicketType from "@/constant/mappingTicketType";
export default {
    extends: baseComponent,
    props: {
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 7rem);'
        },
        minInfo: {
            type: Boolean,
            default: false
        },
        ownedTarget: {
            type: String,
            default: null
        },
    },
    components: {
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBox,
        }),
        addRefundTransaction: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/transactions/AddRefundTransaction.vue"),
            loading: SkeletonBox,
        }),
        reportSuportTicketModalContent: lazyLoadComponent({
            componentFactory: () => import("@/views/modal/RentalService/ReportSupportTicket"),
            loading: SkeletonBox,
        }),
    },
    watch: {
        ownedTarget(newVal) {
            if (newVal !== null) this.getAllResult(1);
        }
    },
    data() {
        return {
            downloadApiHost: process.env.VUE_APP_API_HOST + "api/gpuhub-transaction/export-transaction-by-month/",
            debounceFnc: null,
            debounceFilterFnc: null,
            debouncePagingFnc: null,
            colorGpuNodeColor: colorGpuNodeColor,
            elements: {
                queryAction: "",
                querySearch: "",
                filterItem: {
                    staffId: '',
                },
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "created_at",
                    directionSort: "desc"
                }
            },
            rentalPackage: [],
            toogleFilter: false,
            elementDetail: null,

            refundModel: null,
            rechargeModel: null,
            exportTestStatisticsDataOptions: {
                month: moment().month(),
                year: moment().year()
            },
            providerSelection: providerSelection,
            exportMonthSelect: (new Date()),
            exportLoading: false,
            reportTicketModal: {
                isShowModal: false,
                targetItem: null,
                userInfo: null,
                machineInfo: null,
                postModelData: null
            }
        };
    },
    filters: {
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            staffsInfo: state => state.staffs,
            listStaff: state => state.staffs,
            decentralization: state => state.auth.decentralization,
            $emailAllowedChangeSource: (state) => state.emailAllowedChangeSource,
        }),
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 20,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "created_at",
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                ownedTarget: this.ownedTarget,
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                filterItem: { ...this.elements.filterItem }
            };
        },
        transactionStatusFilter() {
            return Object.keys(transactionStatusText).map(x => {
                return { id: x, text: transactionStatusText[x] };
            });
        },
        yearSelection() {
            var currentYear = new Date().getFullYear(), years = [];
            let startYear = 2019;
            while (startYear <= currentYear) {
                years.push(startYear++);
            }
            return years;
        },
        transactionStaffSelection() {
            return this.staffsInfo.filter(x => x.mainDepartment === 'OPERATING_STAFF' && x.status === 1 && !x.delete)
                .concat([
                    { email: "giangdtt@irender.vn", fullName: "Trà Giang" },
                    { email: "huyennt@irender.vn", fullName: "Nguyễn Huyền" },
                    { email: "quynhbtn@irender.vn", fullName: "Ngọc Quỳnh" }
                ]).map((item) => {
                    return { id: item.email, text: `${item.fullName} (${item.email})` }
                });
        },
        isShowRevenueFilter() {
            return this.checkAccess('GPUHUBTRANSACTION_VIEW_REAL_TRANS') && this.checkAccess('GPUHUBTRANSACTION_VIEW_TEST_TRANS')
        }
    },
    created() {

    },
    mounted() {
        const decentralization = this.decentralization;
        if (decentralization && decentralization.length !== 0) {
            this.getRevenueViewType();
            this.getAllResult(1);
            this.debounceFnc = debounce(() => {
                this.getSearchQueryResult(1);
            }, 1000);
            this.debounceFilterFnc = debounce(() => {
                this.getFilterQueryResult(1);
            }, 1000);
            this.debouncePagingFnc = debounce(() => {
                this.getAllResult(1);
            }, 1000);
        }
        else {
            const instanceVue = this;
            this.$store.dispatch('auth/decentralizationInfo', {
                callback(decentralizationData) {
                    console.log('decentralization', this.decentralizationData);
                    instanceVue.getRevenueViewType();
                    instanceVue.getAllResult(1);
                    instanceVue.debounceFnc = debounce(() => {
                        instanceVue.getSearchQueryResult(1);
                    }, 1000);
                    instanceVue.debounceFilterFnc = debounce(() => {
                        instanceVue.getFilterQueryResult(1);
                    }, 1000);
                    instanceVue.debouncePagingFnc = debounce(() => {
                        instanceVue.getAllResult(1);
                    }, 1000);
                }
            });
        }
    },
    methods: {
        isShowRefund(provider) {
            let targetProvider = providerSelection.find(x => x.id === provider);
            if (targetProvider && targetProvider !== null) {
                return targetProvider.isTargetMainBalance;
            }
            return false;
        },
        getRevenueViewType() {
            //console.log('getRevenueViewType');
            //console.log('GPUHUBTRANSACTION_VIEW_REAL_TRANS', this.checkAccess('GPUHUBTRANSACTION_VIEW_REAL_TRANS'));
            //console.log('GPUHUBTRANSACTION_VIEW_TEST_TRANS', this.checkAccess('GPUHUBTRANSACTION_VIEW_TEST_TRANS'));
            if (!this.isShowRevenueFilter) {
                if (this.checkAccess('GPUHUBTRANSACTION_VIEW_REAL_TRANS') && this.checkAccess('GPUHUBTRANSACTION_VIEW_TEST_TRANS')) {
                    this.elements.filterItem.dataTypeView = 0;
                    localStorage.setItem('dataTypeView', this.elements.filterItem.dataTypeView);
                    return 0;
                }
                if (this.checkAccess('GPUHUBTRANSACTION_VIEW_REAL_TRANS') && !this.checkAccess('GPUHUBTRANSACTION_VIEW_TEST_TRANS')) {
                    this.elements.filterItem.dataTypeView = 1;
                    localStorage.setItem('dataTypeView', this.elements.filterItem.dataTypeView);
                    return 1;
                }
                if (!this.checkAccess('GPUHUBTRANSACTION_VIEW_REAL_TRANS') && this.checkAccess('GPUHUBTRANSACTION_VIEW_TEST_TRANS')) {
                    this.elements.filterItem.dataTypeView = 2;
                    localStorage.setItem('dataTypeView', this.elements.filterItem.dataTypeView);
                    return 2;
                }
            }
            else {
                let dataTypeView = parseInt(localStorage.getItem('dataTypeView')) || 0;
                return dataTypeView;
            }
        },
        getElementsList(pageNumber) {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.showLoading();
            gpuHubTransactionApi.getElementsList(pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.hideLoading();
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);

            });
        },
        getAllResult(pageNumber) {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            let isTransactionsWithRevenue = false; //(localStorage.getItem('isTransactionsWithRevenue') === 'true') || false;
            let dataTypeView = this.getRevenueViewType();
            //console.log('getAllResult dataTypeView', dataTypeView);
            this.elements.queryAction = "";
            this.elements.filterItem = {
                ...this.elements.filterItem,
                isTransactionsWithRevenue: isTransactionsWithRevenue,
                dataTypeView: dataTypeView,
                staffId: this.elements.filterItem.staffId
            };
            this.getElementsList(pageNumber);
        },
        getSearchQueryResult(pageNumber) {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            let isTransactionsWithRevenue = false; //(localStorage.getItem('isTransactionsWithRevenue') === 'true') || false;
            let dataTypeView = this.getRevenueViewType();
            //console.log('getSearchQueryResult dataTypeView', dataTypeView);
            this.elements.queryAction = "search";
            this.elements.filterItem = {
                ...this.elements.filterItem,
                isTransactionsWithRevenue: isTransactionsWithRevenue,
                dataTypeView: dataTypeView,
                staffId: this.elements.filterItem.staffId
            };
            this.getElementsList(pageNumber);
        },
        getFilterQueryResult(pageNumber) {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            let isTransactionsWithRevenue = false; //(localStorage.getItem('isTransactionsWithRevenue') === 'true') || false;
            let dataTypeView = this.getRevenueViewType();
            //console.log('getFilterQueryResult dataTypeView', dataTypeView);
            this.elements.filterItem = {
                ...this.elements.filterItem,
                isTransactionsWithRevenue: isTransactionsWithRevenue,
                dataTypeView: dataTypeView,
                staffId: this.elements.filterItem.staffId
            };
            this.elements.queryAction = "filter";
            this.getElementsList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            switch (this.elements.queryAction) {
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                default:
                    this.getAllResult(pageNumber);
                    break;
            }
        },
        refreshClick() {
            if (this.ownedTarget === null) this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.elements.filterItem = {};
            this.elements.querySearch = "";
            this.getAllResult(1);
        },
        autoCheckInput(isDebounce) {
            if (this.isShowRevenueFilter) {
                localStorage.setItem('isTransactionsWithRevenue', this.elements.filterItem.isTransactionsWithRevenue);
                localStorage.setItem('dataTypeView', this.elements.filterItem.dataTypeView);
            }
            this.elements.queryAction === 'search' ? this.onSearchInput(isDebounce) : (this.elements.queryAction === 'filter' ? this.onFilterInput(isDebounce) : this.onPagingInput(isDebounce))
        },
        onPagingInput(isDebounce) {
            if (isDebounce) {
                if (this.debouncePagingFnc) this.debouncePagingFnc();
            } else {
                this.getAllResult(1);
            }
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        onFilterInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        viewDetailInfo(targetItem) {
            this.$set(this, "elementDetail", targetItem);
            this.$set(this, "rechargeModel", {
                pickedSource: 'gpuHub',
                amount: targetItem.amount,
                pointConversion: targetItem.transactionAmount,
                pointExtraConversion: targetItem.transactionAmountExtra,
                balance: targetItem.balance,
                extraBalance: targetItem.extraBalance,
                provider: targetItem.transactionProvider,
                reason: targetItem.transactionProviderRefId,
                staffEmail: targetItem.currentStaff,
                billingName: targetItem.billingName,
                billingAddress: targetItem.billingAddress,
                billingPhone: targetItem.billingPhone,
                billingTaxCode: targetItem.billingTaxCode
            });
            this.$nextTick(() => {
                $("#detailModifyModalInfo").modal({ backdrop: 'static', keyboard: true, });
            });
        },

        viewExportDateChoose() {
            this.$nextTick(() => {
                $("#exportRechargeTestStaisticsModal").modal({ backdrop: 'static', keyboard: true, });
            });
        },

        closeDetailInfo() {
            $("#detailModifyModalInfo").modal("hide");
            this.$set(this, "elementDetail", null);
            this.$set(this, "rechargeModel", null);
        },
        exportData() {
            let host = process.env.VUE_APP_API_HOST.slice(0, -1);
            gpuHubTransactionApi.exportListTransaction(1, this.requestParam).then(response => {
                if (response.data && response.data.result === 0) {
                    this.$message({
                        message: 'Congrats, perform action succeed.',
                        type: 'success'
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        exportTestStatisticsData() {
            let host = process.env.VUE_APP_API_HOST.slice(0, -1);
            window.open(host + gpuHubTransactionApi.exportRechargeTestStatistics(this.exportTestStatisticsDataOptions.month, this.exportTestStatisticsDataOptions.year), '_blank');
        },
        onFilterToday() {
            let startDate = moment().startOf('day').format('DD/MM/YYYY');
            let endDate = moment().startOf('day').add(1, 'days').format('DD/MM/YYYY');
            this.elements.filterItem.createdFilter = [startDate, endDate];
            this.getFilterQueryResult(1);
        },
        updateTransaction() {
            commonAct.showConfirm("Modify transaction infomations ?", () => {
                let request = Object.assign(
                    {
                        transactionId: this.elementDetail.id,
                        deviceInformation: JSON.stringify(this.getInformationClient()),
                    },
                    this.rechargeModel);
                gpuHubTransactionApi.updateTransaction(request).then(response => {
                    if (response.data.result === 0) {
                        let foundedItem = this.elements.data.find(x => { return x.id === this.elementDetail.id });
                        if (foundedItem && foundedItem !== null) {
                            this.$set(foundedItem, "amount", this.rechargeModel.amount);
                            this.$set(foundedItem, "transactionAmount", this.rechargeModel.pointConversion);
                            this.$set(foundedItem, "balance", this.rechargeModel.balance);
                            this.$set(foundedItem, "transactionProvider", this.rechargeModel.provider);
                            this.$set(foundedItem, "transactionProviderRefId", this.rechargeModel.reason);
                            this.$set(foundedItem, "currentStaff", this.rechargeModel.staffEmail);
                            this.closeDetailInfo();
                        }
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                });
            });
        },
        deleteTransaction() {
            commonAct.showConfirm("Delete transaction infomations ?", () => {
                let request = Object.assign(
                    {
                        transactionId: this.elementDetail.id,
                        deviceInformation: JSON.stringify(this.getInformationClient()),
                    },
                    this.rechargeModel);
                gpuHubTransactionApi.deleteTransaction(request).then(response => {
                    if (response.data.result === 0) {
                        let foundedItemIndex = this.elements.data.findIndex(x => { return x.id === this.elementDetail.id });
                        this.elements.data.splice(foundedItemIndex, 1);
                        this.closeDetailInfo();
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                });
            });
        },
        openAddRefundTransaction(elementTarget) {
            //this.$set(this, "refundModel", {
            //    userId: elementTarget.userInfo.username,
            //    transactionId: elementTarget.id,
            //    transactionCode: elementTarget.transactionCode,
            //    provider: elementTarget.transactionProvider,
            //    amount: elementTarget.amount
            //});
            //this.$nextTick(() => {
            //    $("#addRefundTransactionModal").modal({ backdrop: 'static', keyboard: true, });
            //});
            commonAct.showConfirm(
                `Mark this transaction refunded. Transaction code <strong>${elementTarget.transactionCode}</strong> ?`,
                () => {
                    let request = {
                        data: elementTarget.id,
                        deviceInformation: JSON.stringify(this.getInformationClient()),
                    };
                    gpuHubTransactionApi.markRefunded(request).then(response => {
                        if (response.data.result === 0) {
                            let foundedItem = this.elements.data.find(x => { return x.id === this.elementDetail.id });
                            foundedItem.isRefunded = 1;
                        } else {
                            commonAct.showError(response.data.message);
                        }
                        this.hideLoading();
                    }).catch(error => {
                        console.error(error);
                        commonAct.showError(error);
                    });
                }
            );
        },
        closeAddRefundTransaction() {
            $("#addRefundTransactionModal").modal("hide");
            this.$set(this, "refundModel", null);
        },
        openExportLessInfoModal() {
            $("#exportLessInfoModal").modal({ backdrop: 'static', keyboard: true, });
        },
        closeExportLessInfoModal() {
            $("#exportLessInfoModal").modal("hide");
        },
        downloadItem({ url, label }) {
            this.exportLoading = true;
            axios.get(url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/xlsx' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = label
                    link.click()
                    URL.revokeObjectURL(link.href);
                    this.exportLoading = false;
                }).catch(console.error)
        },
        onWaypoint({ element }) {
            if (!element.hasOwnProperty("isLazyloadData") || !element.isLazyloadData) {
                getlazyLoadInfo(this, element.userInfo, element.userId);
                this.$set(element, 'isLazyloadData', true);
                console.log("onWaypoint");
            }
        },

        openSupportTicketModal(targetItem) {
            let defaultCategory = mappingTicketType["TRANSACTION"];
            this.$set(this.reportTicketModal, "targetItem", targetItem);
            this.$set(this.reportTicketModal, "userInfo", targetItem.userInfo);
            this.$set(this.reportTicketModal, "machineInfo", null);
            this.$set(this.reportTicketModal, "postModelData", {
                subject: `${targetItem.userInfo.username}`,
                mess: "",
                category_id: defaultCategory,
                type_id: null,
                machineId: null,
                username: `${targetItem.userInfo.username}`,
                name: this.userInfo.fullName,
                targetItemIdentity: targetItem.transactionCode
            });
            this.$nextTick(() => {
                this.$set(this.reportTicketModal, "isShowModal", true);
            });
        },
        closeSupportTicketModal() {
            this.$set(this.reportTicketModal, "isShowModal", false);
            this.$set(this.reportTicketModal, "userInfo", null);
            this.$set(this.reportTicketModal, "machineInfo", null);
            this.$set(this.reportTicketModal, "postModelData", null);
        },
    }
}